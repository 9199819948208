import { BackLink } from "@curiodao/capital-dex-ui-kit";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { EvmFarmCards, ParachainFarmCards } from "../../modules/Farms";
import { PARACHAIN_POOLS } from "../../modules/Farms/_parachain/constants/parachain-pools";
import { IPage } from "../../common/interfaces/IPage";
import { useNetworkContext } from "../../modules/Network";
import { getFarmPools } from "../../modules/Farms/_env/mappers/getFarmPools";
import { useTokenListContext } from "../../contexts/TokenList";

export const FarmPage = ({ isParachain }: IPage) => {
  const { core } = useNetworkContext();
  const { tokenList } = useTokenListContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const farmPool = PARACHAIN_POOLS.find((pool) => pool.id === id);
  const evmFarmPool = core?.farming
    ? getFarmPools(core.farming, tokenList).find(
        (pool) => `${pool.token0.symbol}-${pool.token1.symbol}` === id,
      )
    : null;
  if (isParachain ? !farmPool : !evmFarmPool) {
    return <Navigate to="/farms" />;
  }

  return isParachain && farmPool ? (
    <>
      <BackLink onClick={() => navigate(-1)} text="Back" />
      <ParachainFarmCards farmPool={farmPool} />
    </>
  ) : core && evmFarmPool ? (
    <>
      <BackLink onClick={() => navigate(-1)} text="Back" />
      <EvmFarmCards farmPool={evmFarmPool} />
    </>
  ) : (
    <div />
  );
};
