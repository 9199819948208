import { useEffect, useState } from "react";
import { useWalletContext } from "../../../common/context/Wallet";
import { useBlock } from "../../../common/hooks/useBlock";
import { SystemInfoService } from "@curiodao/capital-dex-sdk/polkadot";

export const useRewardRate = (value: string, activeTab: string) => {
  const { api } = useWalletContext();
  const [minStake, setMinStake] = useState<bigint | undefined>();
  const [rewardRate, setRewardRate] = useState<bigint | undefined>();
  const block = useBlock();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (api) {
      (async () => {
        const systemInfo = new SystemInfoService(api);
        setMinStake(systemInfo.minDelegatorStake());
      })();
    }
  }, [api]);

  useEffect(() => {
    if (api) {
      setLoading(true);
      (async () => {
        const systemInfo = new SystemInfoService(api);
        const rewardRate = await systemInfo.rewardRate(
          value,
          activeTab === "stake",
        );
        setRewardRate(rewardRate);
        setLoading(false);
      })();
    }
  }, [activeTab, api, value, block]);

  return {
    rewardRate,
    minStake,
    loading,
  };
};
