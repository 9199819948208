import { useState } from "react";
import {
  ArrowIcon,
  Modal,
  StatusModal,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import classes from "./TransactionModal.module.css";
import { SwapInfoCard } from "../SwapInfoCard";
import { IToken } from "@curiodao/capital-dex-sdk";
import { parseUnits } from "ethers";
import { ITrade } from "../../interfaces/ITrade";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { ROUTER } from "../../../../../config/router";

interface ITransactionModal {
  inputFrom: string;
  token0: IToken;
  token1: IToken;
  swapParams: ITrade;
  accountId: string;
  onCloseButtonClick: () => void;
}

export const TransactionModal = ({
  inputFrom,
  token0,
  token1,
  swapParams,
  accountId,
  onCloseButtonClick,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const [tonConnectUI] = useTonConnectUI();

  const swap = async () => {
    setReview(false);
    try {
      const txParams = await ROUTER.getSwapJettonToJettonTxParams({
        userWalletAddress: accountId,
        offerJettonAddress: token0.address,
        offerAmount: parseUnits(inputFrom, token0.decimals).toString(),
        askJettonAddress: token1.address,
        minAskAmount: "1",
        queryId: Date.now(),
      });
      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: txParams.to.toString(),
            amount: txParams.value.toString(),
            payload: txParams.body?.toBoc().toString("base64"),
          },
        ],
      });
      setStatus("done");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };
  return (
    <Modal title="Review swap" onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.ConfirmModal}>
          <div className={classes.Info}>
            <TokenPanel
              token0={token0}
              value={parseFloat(inputFrom).toFixed(4)}
              title="From"
              bordered
            />

            <TokenPanel
              token0={token1}
              value={parseFloat(swapParams.ask_units.toString()).toFixed(4)}
              title="To"
              bordered
            />
            <div className={classes.CenterIcon}>
              <ArrowIcon />
            </div>
          </div>
          <SwapInfoCard tokens={[token0, token1]} trade={swapParams} />
          <button className="btn block" onClick={swap}>
            Confirm swap
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action="swap"
          token0={token0}
          token1={token1}
          token0value={inputFrom}
          token1value={swapParams.ask_units.toString()}
          transactionLink={""}
          isSwap
        />
      )}
    </Modal>
  );
};
