import React from "react";
import {
  EvmEarnedSection,
  EvmPools,
  ParachainEarnedSection,
  ParachainPools,
} from "../../modules/Farms";
import { IPage } from "../../common/interfaces/IPage";
import { useNetworkContext } from "../../modules/Network";

export const FarmsPage = ({ isParachain }: IPage) => {
  const { core } = useNetworkContext();

  return isParachain ? (
    <>
      <ParachainEarnedSection />
      <ParachainPools />
    </>
  ) : core ? (
    <>
      <EvmEarnedSection />
      <EvmPools core={core} />
    </>
  ) : (
    <div />
  );
};
