import { useEffect, useState, JSX } from "react";
import classes from "./Header.module.css";
import { Logo, SmallLogo } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Wallet } from "../Wallet";
import { Networks } from "../Networks";
import { useNetworkContext } from "../../../Network";
import { Menu } from "../Menu";
import { MobileMenu } from "../MobileMenu";

export const Header = (): JSX.Element => {
  const { isParachain, core, isTon } = useNetworkContext();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setShowMenu(false);
      }
    });
  }, []);

  return (
    <div className={classes.HeaderWrapper}>
      <div className={classes.HeaderInner}>
        <div className={classes.LogoBlock}>
          <Link to="/">
            <Logo />
            <SmallLogo />
          </Link>
        </div>
        {!showMenu && (
          <Menu showMenu={showMenu} core={core} isParachain={isParachain} />
        )}
        <div className={classes.ButtonsWrap}>
          <Networks />
          <Wallet isParachain={isParachain} isTon={isTon} />
        </div>
        <button
          className={classNames(classes.MobileButton)}
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>
      {showMenu && (
        <MobileMenu
          showMenu={showMenu}
          isParachain={isParachain}
          core={core}
          toggleModal={() => setShowMenu(!showMenu)}
        />
      )}
    </div>
  );
};
