import { useState } from "react";
import classes from "./WaitlistModal.module.css";
import { Input, Modal } from "@curiodao/capital-dex-ui-kit";
import { SubscribeService } from "../../services/Subscribe.service";

export const WaitlistModal = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const [subscribeService] = useState(() => new SubscribeService());
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      await subscribeService.postWhitelist(value, "curio-bitspectrum");
      setError("");
      onCloseButtonClick();
    } catch {
      setError("Error");
    }
  };

  return (
    <Modal title="Joining test network" onCloseButtonClick={onCloseButtonClick}>
      <form className={classes.FormContainer}>
        <Input label="Email" error={error} touched>
          <input
            name="email"
            placeholder="Email address..."
            onChange={(v) => setValue(v.target.value)}
            value={value}
          />
        </Input>
        <button
          className="btn block"
          type="button"
          disabled={!value}
          onClick={handleSubmit}
        >
          Send request
        </button>
      </form>
    </Modal>
  );
};
