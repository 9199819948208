import { useEffect, useState } from "react";
import { SwapService } from "@curiodao/capital-dex-sdk/evm";
import { useNetworkContext } from "../../../Network";
import { Trade, Token } from "@uniswap/sdk";
import { getUniswapToken } from "../../../Tokens";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useTrade = (
  amountIn: string,
  token0: IToken | undefined,
  token1: IToken | undefined,
  isWrapping: boolean,
  pairsForRoute: Token[][],
) => {
  const [swapParams, setSwapParams] = useState<Trade>();
  const [resError] = useState<string | undefined>(undefined);
  const { chainId, core } = useNetworkContext();
  const { tokenList } = useTokenListContext();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const tokenA = getUniswapToken(token0, tokenList);
      const tokenB = getUniswapToken(token1, tokenList);
      if (
        tokenA &&
        tokenB &&
        core?.networkCredentials.rpcUrl &&
        !isWrapping &&
        amountIn.length
      ) {
        try {
          const swapService = new SwapService();
          setLoading(true);
          const swapParams = await swapService.getEthSwapParams(
            tokenA,
            tokenB,
            amountIn,
            pairsForRoute,
            core.networkCredentials.rpcUrl,
            chainId,
            walletProvider,
            token0?.isEther,
            token1?.isEther,
          );

          setSwapParams(swapParams);
          setLoading(false);
        } catch (error) {
          console.error("Error", error);
        }
      } else {
        setSwapParams(undefined);
      }
    })();
  }, [
    amountIn,
    chainId,
    core?.networkCredentials.rpcUrl,
    tokenList,
    pairsForRoute,
    token0,
    token1,
    isWrapping,
    walletProvider,
  ]);

  return { trade: swapParams, resError, loading };
};
