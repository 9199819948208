import { IPool } from "../interfaces/IPool";
import { AxiosInstance } from "axios";
import { IToken } from "@curiodao/capital-dex-sdk";
import { IBalance } from "../../../Tokens/_ton/interfaces/IBalance";
import { parseUnits } from "ethers";
import { ILiquidity } from "../interfaces/ILiquidity";
import { tonDexClient } from "../../../../config/clients";

export class PoolsService {
  async getPositions(
    balances: IBalance[],
    dexClient: AxiosInstance,
  ): Promise<IPool[] | null> {
    try {
      const { data } = await dexClient.get(`/pools`);
      return data.map((pool: IPool) => {
        const balance = balances.find(
          (balance) => balance.jetton.address === pool.address,
        );
        return { ...pool, lp_balance: balance?.balance };
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSimulateProvideLiquidity(
    token0: IToken,
    token1: IToken,
    inputFrom: string,
    inputTo: string,
    dexClient: AxiosInstance,
  ): Promise<ILiquidity | null> {
    try {
      const { data } = await dexClient.post(
        "/pools/provide-liquidity/simulate",
        {
          token_0_address: token0.address,
          token_1_address: token1.address,
          token_0_amount: parseUnits(inputFrom, token0.decimals).toString(),
          token_1_amount: parseUnits(inputTo, token1.decimals).toString(),
          slippage_tolerance: "0.001",
        },
      );
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getPool(poolAddress: string): Promise<IPool | null> {
    try {
      const { data } = await tonDexClient.get(`/pools/${poolAddress}`);
      return data;
    } catch (e) {
      return null;
    }
  }
}
