import { Modal } from "@curiodao/capital-dex-ui-kit";
import { Menu } from "../Menu";
import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";

export const MobileMenu = ({
  showMenu,
  core,
  isParachain,
  toggleModal,
}: {
  showMenu: boolean;
  core: INetworkCore | undefined;
  isParachain: boolean;
  toggleModal: () => void;
}) => {
  return (
    <Modal title="" onCloseButtonClick={toggleModal}>
      <Menu
        closeModal={toggleModal}
        showMenu={showMenu}
        core={core}
        isParachain={isParachain}
      />
    </Modal>
  );
};
