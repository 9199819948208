import { useEffect, useState } from "react";
import { SwapService } from "../services/swap.service";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ITrade } from "../interfaces/ITrade";
import { tonDexClient } from "../../../../config/clients";

export const useSimulateSwap = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  amount: string,
) => {
  const [trade, setTrade] = useState<ITrade | undefined>();
  const [loading, setLoading] = useState(false);
  const [noLiquidity, setNoLiquidity] = useState(false);

  useEffect(() => {
    (async () => {
      if (token0 && token1 && amount.length) {
        setNoLiquidity(false);
        setLoading(true);
        const swapService = new SwapService();
        swapService
          .getSimulateSwap(token0, token1, amount, tonDexClient)
          .then((res) => {
            if (res) {
              setTrade(res);
            } else {
              setNoLiquidity(true);
            }
            setLoading(false);
          });
      }
    })();
  }, [amount, token0, token1]);

  return { trade, loading, noLiquidity };
};
