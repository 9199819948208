import classes from "./RemoveLiquidity.module.css";
import {
  BackLink,
  Tooltip,
  useModalByName,
  Processing,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { IToken } from "@curiodao/capital-dex-sdk";
import { usePoolByTokens } from "../../hooks/usePoolByTokens";
import { useTonAddress } from "@tonconnect/ui-react";
import { parseUnits } from "ethers";
import { RemoveTransactionModal } from "../RemoveTransactionModal";

interface IRemoveLiquidity {
  tokens: IToken[];
}

export const RemoveLiquidity = ({ tokens }: IRemoveLiquidity) => {
  const navigate = useNavigate();
  const { modal, changeModal, closeModal } = useModalByName();
  const tonAddress = useTonAddress(false);
  const [inputFrom, setInputFrom] = useState("");

  const { pool } = usePoolByTokens(tokens[0], tokens[1], tonAddress);

  const error =
    !tokens[0] || !tokens[1]
      ? "Select tokens"
      : !inputFrom.length || parseFloat(inputFrom) === 0
        ? "Enter amount"
        : pool?.userShare &&
            inputFrom.length &&
            parseUnits(inputFrom, 9) > BigInt(pool.userShare)
          ? `Insufficient user share balance`
          : undefined;

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classes.Headline}>
        <BackLink text="Remove liquidity" onClick={() => navigate(-1)} />
        <Tooltip text="Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive." />
      </div>
      {/*<TokensPanel>*/}
      <TokenPanel
        title="Input"
        token0={tokens[0]}
        token1={tokens[1]}
        value={inputFrom}
        setValue={setInputFrom}
        tokenBalance={getShowBalance(pool?.userShare, 9)}
      />
      <div />
      {/*<PoolPanel*/}
      {/*  title="Output"*/}
      {/*  value0={tokenAReturns}*/}
      {/*  value1={tokenBReturns}*/}
      {/*  tokens={tokens}*/}
      {/*/>*/}
      {/*</TokensPanel>*/}
      {/*{estimatedPool &&*/}
      {/*  tokens[0] &&*/}
      {/*  tokens[1] &&*/}
      {/*  (estimatedPool.loading ? (*/}
      {/*    <Skeleton height={51} count={1} />*/}
      {/*  ) : (*/}
      {/*    <div className="card bordered">*/}
      {/*      <InfoRow*/}
      {/*        title="Rates"*/}
      {/*        value={*/}
      {/*          <>*/}
      {/*            1 {tokens[0].symbol} ={" "}*/}
      {/*            {getShowBalance(estimatedPool.incrementB, tokens[1].decimals)}{" "}*/}
      {/*            {tokens[1].symbol}*/}
      {/*            <SwapIcon />*/}
      {/*          </>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {tonAddress ? (
        <button
          className={"btn block"}
          onClick={() => changeModal("remove")}
          disabled={!!error || modal === "remove"}
        >
          {error ?? (modal === "remove" ? <Processing /> : "Remove")}
        </button>
      ) : (
        <button className={"btn block"} onClick={() => changeModal("wallet")}>
          Connect wallet
        </button>
      )}
      {modal === "remove" && (
        <RemoveTransactionModal
          token0={tokens[0]}
          token1={tokens[1]}
          accountId={tonAddress}
          onCloseButtonClick={closeModal}
        />
      )}
    </div>
  );
};
