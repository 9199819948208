import { useEffect, useState } from "react";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { IPosition } from "../../_parachain/interfaces/IPosition";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";

export const usePoolsInfo = (
  account: string | undefined,
): { positions: IPosition[]; loading: boolean } => {
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId } = useNetworkContext();
  const { tokenList } = useTokenListContext();
  const [poolsInfo, setPoolsInfo] = useState<(IPosition | undefined)[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (account && walletProvider) {
          const poolsService = new PoolsService();
          setLoading(true);

          setPoolsInfo(
            (
              await poolsService.getUserPositions(
                tokenList,
                account,
                chainId,
                walletProvider,
              )
            ).filter((position) => !position?.userPoolBalance.equalTo(0n)),
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching pools info", error);
      }
    })();
  }, [account, chainId, tokenList, walletProvider]);

  return {
    positions: poolsInfo.filter(
      (position) => position !== undefined,
    ) as IPosition[],
    loading,
  };
};
