import { IToken } from "@curiodao/capital-dex-sdk";
import { IJetton } from "../interfaces/IJetton";

export const jettonsToTokens = (jettons: IJetton[]): IToken[] =>
  jettons.map((jetton) => ({
    name: jetton.display_name,
    symbol: jetton.symbol.length > 0 ? jetton.symbol : jetton.display_name,
    decimals: Number(jetton.decimals),
    chainId: 0,
    address: jetton.wallet_address,
    logoUri: jetton.image_url,
  }));
