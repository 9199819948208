import { ITrade } from "../interfaces/ITrade";
import { pTON } from "@ston-fi/sdk";
import { SenderArguments } from "@ton/core";
import { AxiosInstance } from "axios";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ROUTER } from "../../../../config/router";
import { parseUnits } from "ethers";

export class SwapService {
  private proxyTon = new pTON.v1();

  async getSimulateSwap(
    token0: IToken,
    token1: IToken,
    amount: string,
    dexClient: AxiosInstance,
  ): Promise<ITrade | null> {
    try {
      const { data } = await dexClient.post("/swap/simulate", {
        offer_address: token0.address,
        ask_address: token1.address,
        units: parseUnits(amount, token0.decimals).toString(),
        slippage_tolerance: "0.001",
        referral_address: null,
      });
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSwapTxParams(
    token0: IToken,
    token1: IToken,
    amount: string,
    accountId: string,
  ): Promise<SenderArguments | undefined> {
    try {
      const data = {
        userWalletAddress: accountId,
        offerAmount: amount,
        minAskAmount: "1",
      };

      if (!token0.isEther && !token1.isEther) {
        return ROUTER.getSwapJettonToJettonTxParams({
          ...data,
          offerJettonAddress: token0.address,
          askJettonAddress: token1.address,
        });
      } else if (token0.isEther) {
        return ROUTER.getSwapTonToJettonTxParams({
          ...data,
          proxyTon: this.proxyTon,
          askJettonAddress: token1.address,
        });
      } else {
        return ROUTER.getSwapJettonToTonTxParams({
          ...data,
          offerJettonAddress: token0.address,
          proxyTon: this.proxyTon,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
