import classes from "./Banner.module.css";
import { Link } from "react-router-dom";

export const Banner = () => {
  return (
    <div className={classes.Banner}>
      <p>
        🎉 Curio is moving to the Bitcoin blockchain! #RWA on Bitcoin is going
        live soon! 🎉
      </p>
      <Link to="/#curio-bitcoin-chain">Joining test network</Link>
    </div>
  );
};
