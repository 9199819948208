import { useState } from "react";
import { Modal, StatusModal } from "@curiodao/capital-dex-ui-kit";
import classes from "./RemoveTransactionModal.module.css";
import { IToken } from "@curiodao/capital-dex-sdk";
import { tonClient } from "../../../../../config/clients";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { ROUTER } from "../../../../../config/router";

interface ITransactionModal {
  token0: IToken;
  token1: IToken;
  accountId: string;
  onCloseButtonClick: () => void;
}

export const RemoveTransactionModal = ({
  token0,
  token1,
  accountId,
  onCloseButtonClick,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const [tonConnectUI] = useTonConnectUI();

  const handle = async () => {
    setReview(false);
    try {
      const pool = tonClient.open(
        await ROUTER.getPool({
          token0: token0.address,
          token1: token1.address,
        }),
      );

      const lpTokenWallet = tonClient.open(
        await pool.getJettonWallet({
          ownerAddress: accountId,
        }),
      );

      const lpTokenWalletData = await lpTokenWallet.getWalletData();

      const txParams = await pool.getBurnTxParams({
        amount: lpTokenWalletData.balance,
        responseAddress: accountId,
        queryId: 12345,
      });
      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: txParams.to.toString(),
            amount: txParams.value.toString(),
            payload: txParams.body?.toBoc().toString("base64"),
          },
        ],
      });
      setStatus("done");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };

  return (
    <Modal title={"Remove"} onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.Confirm}>
          {/*<PoolPanel*/}
          {/*  title="Receive"*/}
          {/*  value0={token0Amount}*/}
          {/*  value1={token1Amount}*/}
          {/*  tokens={[token0, token1]}*/}
          {/*  bordered*/}
          {/*/>*/}
          {/*<div className="card bordered">*/}
          {/*  <InfoRow*/}
          {/*    title="Burned"*/}
          {/*    value={`${toHuman(shareToRemove)} ${token0.symbol}-${token1.symbol}`}*/}
          {/*  />*/}
          {/*  <InfoRow*/}
          {/*    title="Rates"*/}
          {/*    value={*/}
          {/*      <>*/}
          {/*        1 {token0.symbol} = {`${rates} ${token1.symbol}`}*/}
          {/*        <SwapIcon />*/}
          {/*      </>*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          <button className="btn block" onClick={handle}>
            Confirm removing
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action={"remove"}
          token0={token0}
          token1={token1}
          token0value={"-"}
          token1value={"-"}
          transactionLink={""}
        />
      )}
    </Modal>
  );
};
