import React from "react";
import { SwapIcon, InfoRow } from "@curiodao/capital-dex-ui-kit";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ITrade } from "../../interfaces/ITrade";
import { formatUnits } from "ethers";

export const SwapInfoCard = ({
  tokens,
  trade,
}: {
  tokens: (IToken | undefined)[];
  trade: ITrade;
}) => {
  return (
    <div className="card bordered">
      <InfoRow
        title="Rates"
        value={
          <>
            1 {tokens[0]?.symbol} = {trade.swap_rate} {tokens[1]?.symbol}{" "}
            <SwapIcon />
          </>
        }
      />
      <InfoRow
        title="Minimum received"
        info="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
        value={`${formatUnits(trade.min_ask_units, tokens[1]?.decimals)} ${tokens[1]?.symbol}`}
      />
      <InfoRow
        title="Price impact"
        info="The difference between the market price and estimated price due to trade size."
        value={`${parseFloat(trade.price_impact).toFixed(2)}%`}
      />
      <InfoRow
        title="Liquidity provider fee"
        info="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
        value={`${trade.fee_percent} TON`}
      />
    </div>
  );
};
