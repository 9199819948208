import { env } from "../../../core/env";
import axios from "axios";

export class SubscribeService {
  private api = axios.create({
    baseURL: env.API_ENDPOINT,
  });

  async postWhitelist(email: string, type?: string) {
    try {
      return this.api.post("/subscription/subscribe", { email, type });
    } catch (e) {
      return undefined;
    }
  }
}
