import { createContext, JSX } from "react";
import { TokenListContextType } from "./types";
import { useNetworkContext } from "../../modules/Network";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ITokenList } from "../../common/interfaces";
import axios from "axios";
import { env } from "../../core/env";
import cgtLogo from "../../assets/img/icons/cgt-token.svg";
import { ETHER, JettonsService } from "../../modules/Tokens";
import { PARACHAIN_TOKENS_LIST } from "./constants";
import { useQuery } from "@tanstack/react-query";
import { tonDexClient } from "../../config/clients";

export const TokenListContext = createContext<TokenListContextType | null>(
  null,
);

const fetchEvmTokenList = async (): Promise<IToken[] | undefined> => {
  try {
    const res = await axios.get(env.ETH_TOKEN_LIST ?? "");
    const data: ITokenList = res.data;
    return [
      ...data.tokens.map((token) => ({
        ...token,
        logoUri: token.symbol === "CGT" ? cgtLogo : token.logoURI,
      })),
    ];
  } catch (e) {
    console.error(e);
  }
};

export const TokenListProvider = ({ children }: { children: JSX.Element }) => {
  const { chainId, core, isParachain, isTon } = useNetworkContext();

  const { data: tonTokenList, isPending: isTonPending } = useQuery({
    queryKey: ["tonTokenListData"],
    queryFn: () => new JettonsService(tonDexClient).getTokenList(),
  });

  const { data: evmTokenList, isPending: isEvmPending } = useQuery({
    queryKey: ["evmTokenListData"],
    queryFn: fetchEvmTokenList,
  });

  return (
    <TokenListContext.Provider
      value={{
        loading: isTon ? isTonPending : isEvmPending,
        tokenList: isParachain
          ? PARACHAIN_TOKENS_LIST
          : isTon
            ? tonTokenList ?? []
            : [
                ...(evmTokenList ?? []).filter(
                  (token) => token.chainId === chainId,
                ),
                ...(core && !core.etherNotInTokenList
                  ? [
                      core.customEth ?? {
                        ...ETHER,
                        chainId,
                      },
                    ]
                  : []),
              ],
      }}
    >
      {children}
    </TokenListContext.Provider>
  );
};
