import { useState } from "react";
import { toToken } from "../../../../common/libs/support";
import {
  useParachainTokenBalance,
  getTokenInfoBySymbol,
} from "../../../Tokens";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useTokensPanel = (
  account: string | undefined,
  modal: string,
  inputFrom: string,
  pair?: string[],
) => {
  const [tokens, setTokens] = useState<(IToken | undefined)[]>([
    getTokenInfoBySymbol(pair ? pair[0] : "CGT"),
    getTokenInfoBySymbol(pair ? pair[1] : ""),
  ]);

  const { balance: token0Balance } = useParachainTokenBalance(tokens[0]);
  const { balance: token1Balance } = useParachainTokenBalance(tokens[1]);

  const selectToken = (token: IToken) => {
    setTokens(
      modal === "token-from"
        ? token.name === tokens[1]?.name
          ? [token, tokens[0]]
          : [token, tokens[1]]
        : token.name === tokens[0]?.name
          ? [tokens[1], token]
          : [tokens[0], token],
    );
  };

  return {
    tokens,
    setTokens,
    token0Balance,
    token1Balance,
    selectToken,
    error: !account
      ? "Connect wallet"
      : !tokens[0] || !tokens[1]
        ? "Select tokens"
        : !inputFrom.length
          ? "Enter amount"
          : toToken(inputFrom, tokens[0]?.decimals) > BigInt(token0Balance)
            ? `Insufficient ${tokens[0]?.symbol} balance`
            : undefined,
  };
};
