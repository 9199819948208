import { useEffect, useState } from "react";
import { useWalletContext } from "../../../../common/context/Wallet";
import { SwapService } from "@curiodao/capital-dex-sdk/polkadot";
import { SwapParameters } from "capital-dex-parachain-sdk/build/sdk-swap/swap-parameters";
import { AxiosError } from "axios";
import { useBlock } from "../../../../common/hooks/useBlock";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useSwapParams = (
  tokens: (IToken | undefined)[],
  inputFrom: string,
) => {
  const { api } = useWalletContext();
  const block = useBlock();
  const [outputAmount, setOutputAmount] = useState("");
  const [priceImpact, setPriceImpact] = useState("");
  const [fee, setFee] = useState("");
  const [price, setPrice] = useState("");
  const [swapParams, setSwapParams] = useState<SwapParameters>();
  const [resError, setResError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (tokens[0] && tokens[1] && inputFrom && api) {
          const swap = new SwapService(api);
          setLoading(true);
          const swapParams = await swap.getSwapParams(
            [tokens[0], tokens[1]],
            inputFrom,
          );
          if (swapParams) {
            setSwapParams(swapParams);
            setOutputAmount(swapParams.output.balance.toString());
            setPriceImpact(swapParams.priceImpact.toNumber().toFixed(4));
            setFee(swapParams.exchangeFee.toString());
            setPrice(
              (
                swapParams.output.balance.toNumber() /
                swapParams.input.balance.toNumber()
              ).toFixed(4),
            );
            setResError(undefined);
          }
        } else {
          setSwapParams(undefined);
          setOutputAmount("");
          setPriceImpact("");
          setFee("");
          setPrice("");
          setLoading(false);
        }
      } catch (e) {
        setSwapParams(undefined);
        setOutputAmount("");
        setPriceImpact("");
        setFee("");
        setPrice("");
        setResError((e as AxiosError).message);
      }
    })();
  }, [tokens, inputFrom, api, block]);

  return {
    outputAmount,
    priceImpact,
    fee,
    price,
    resError,
    swapParams,
    loading,
  };
};
