import { INetworkCore } from "../../../common/_evm/interfaces/INetworkCore";
import { IMenuItem } from "../interfaces/IMenuItem";
import { IMenuItemId } from "../interfaces/IMenuItemId";

export const getMenu = (
  menu: IMenuItem[],
  networkCore: INetworkCore | undefined,
  isParachain: boolean,
) => {
  const conditions: { [key in IMenuItemId]?: boolean } = {
    cgt: !isParachain && !!networkCore?.cgtCurSwapContract,
    wrapping: !isParachain && !!networkCore?.wrapping,
    farms: isParachain || !!networkCore?.farming,
    staking: isParachain,
  };

  return menu.filter(
    (menuItem) =>
      conditions[menuItem.id] === undefined ||
      (menuItem.id && conditions[menuItem.id]),
  );
};
